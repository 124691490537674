<template>
  <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top bg-title">
    <div class="container px-5">
      <a class="navbar-brand white-light" href="#/">
          <img src="../../../../public/images/HowCost-title-logo(white).png" alt="/" width="200px" height="49px">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse menu-fade " id="navbarResponsive">
        <div class="navbar-nav ms-auto">
          <!-- 회원 -->
          <div v-if="isLogin" class="c-nav">
            <!-- <li class="nav-item"><a class="nav-link menu-title-color fade-out" href="#" target="_self">제품견적</a></li>
            <li class="nav-item"><a class="nav-link menu-title-color fade-out" href="#" target="_self">스펙북</a></li> -->
          </div>

          <!-- 권한 별 메뉴 -->
          <template v-if=" isLogin && userInfo.user.role === 'A' ">
            <div class="c-nav">
              <li class="nav-item"><a class="nav-link menu-title-color fade-out" href="#/spec-shop" target="_self">스펙샵</a></li>
            </div>
          </template>

          <!-- 공통 -->
          <template>
            <div class="c-nav">
              <li class="nav-item"><a class="nav-link menu-title-color fade-out" href="#/service/howmuch" target="_self">하우머치</a></li>
            </div>
            <div class="c-nav">
              <li class="nav-item"><a class="nav-link menu-title-color fade-out" href="#/introduction" target="_self">제품소개</a></li>
            </div>
            <div class="c-nav">
              <li class="nav-item"><a class="nav-link menu-title-color fade-out" href="#/store" target="_self">제품구매</a></li>
            </div>
            <div class="c-nav">
              <li class="nav-item"><a class="nav-link menu-title-color fade-out" href="http://xcost.me/" target="_blank">커뮤니티</a></li>
            </div>
          </template>

          <!-- 비회원 -->
          <template v-if="!isLogin">
            <div class="c-nav">
              <li class="nav-item"><a class="nav-link menu-title-color fade-out" href="#/service/register" target="_self">회원가입</a></li>
            </div>
            <div class="c-nav">
              <li class="nav-item"><a class="nav-link menu-title-color fade-out" href="#/service/login" target="_self">로그인</a></li>
            </div>
          </template>

          <!-- 회원 개인 드롭다운 메뉴 -->
          <template v-if="isLogin">
            <div class="c-nav noto-sans">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <b-avatar variant="info"></b-avatar>
                </template>

                <div class="display-base" style="padding: 0.5rem 1.25rem;">
                  <div class="">
                    <b-avatar variant="info" size="4.25em"></b-avatar>
                  </div>
                  <div class="ml-3">
                    <h3 class="mb-1" style="font-weight: 500;">{{ userInfo.user.nick }}</h3>
                    <p class="mb-0">{{ userInfo.user.email }}</p>
                    <a href="#" @click="$bvModal.show('bv-modal-subscribe')">구독관리</a>
                  </div>
                </div>
                <div class="mt-1 mb-1" style="border-bottom: solid 1px rgba(183, 183, 183, 0.496);"></div>
                <!-- <b-dropdown-item href="#">내 주소록</b-dropdown-item>
                <div class="mt-1 mb-1" style="border-bottom: solid 1px rgba(183, 183, 183, 0.496);"></div> -->
                <b-dropdown-item class="fs-11" href="#/order-list">주문내역</b-dropdown-item>
                <b-dropdown-item class="fs-11" href="#/service/member-password-verify">정보수정</b-dropdown-item>
                <b-dropdown-item class="fs-11" href="#/service/coupon-use">쿠폰함</b-dropdown-item>
                <div class="mt-1 mb-1" style="border-bottom: solid 1px rgba(183, 183, 183, 0.496);"></div>
                <b-dropdown-item class="fs-11" href="#" @click="logout">로그아웃</b-dropdown-item>
              </b-dropdown>
            </div>
          </template>

        </div>
      </div>
    </div>

    <accountManager/>
  </nav>
</template>

<script>
import accountManager from '../accountManager/AccountManager.vue';
import {
  apiCall,
  warpSite,
} from '../../../common/utils'
import store from '@/store';
// import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  components: {
    accountManager,
  },
  data(){
    return {
      // isVisible: false,
      isLogin: false,
      userInfo: null,
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        document.getElementById('navbarResponsive').classList.remove('show');
      }
    }
  },

  computed: {
  },

  async created() {
    const resAuth = await store.dispatch('userStore/VERIFY');
    if (resAuth.isAuthenticated) {
      const state = await this.$store.state.userStore;
      // console.log("resAuth --------------> ", resAuth);
      // console.log("state --------------> ", state);
      this.isLogin = resAuth.isAuthenticated;

      if (this.isLogin) {
        this.userInfo = resAuth;
      }
    }
  },

  mounted() {
  },

  methods: {
    async logout() {
      await this.$store.dispatch('userStore/LOGOUT');
      window.location.reload();
    },
  },
}
</script>